module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"paganidesignwatch.net – Pagani Design Watch Official Store","short_name":"💯 Pagani Design Watch","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9fac9c95c83e50e98cd8c7b465c1fef5"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","url":"https://www.paganidesignwatch.net/","site_name":"Pagani Design Watch Official Store"},"twitter":{"handle":"@onemorewatches","site":"https://www.paganidesignwatch.net","cardType":"summary_large_image"},"facebook":{"appId":"2636999079954760"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-shopify-poulo/gatsby-browser.js'),
      options: {"plugins":[],"activatePWA":true,"dirName":"E:\\learning\\gatsby\\paganidesignwatch.net\\paganidesignwatches","navigation":{"menu":[{"type":"Menu","displayName":"Watches","link":"","subMenu":[{"displayName":"Men's Watches","link":"/all-mens-watches"},{"displayName":"Women's Watches","link":"/all-womens-watches"},{"displayName":"Watch Straps","link":"/all-straps"}]}]},"footerNavigation":{"menu":[{"type":"Link","displayName":"SHOP","link":"","subMenu":[{"displayName":"Men's Watches","link":"/all-mens-watches"},{"displayName":"Women's Watches","link":"/all-womens-watches"},{"displayName":"Watch Straps","link":"/all-straps"},{"displayName":"About Us","link":"/about"},{"displayName":"Affiliate","link":"https://www.affiliatly.com/af-1037030/affiliate.panel?mode=register"}]},{"type":"Link","displayName":"HELP","link":"","subMenu":[{"displayName":"Shipping Policy","link":"/shipping"},{"displayName":"Return & Refund Policy","link":"/returns"},{"displayName":"Order Tracking","link":"/track-order"},{"displayName":"FAQ","link":"/faq"},{"displayName":"Contact Us","link":"/contact"}]},{"type":"Support","link":null,"displayName":"","subMenu":[{"displayName":"Terms of Service","link":"/terms-of-service"},{"displayName":"Privacy Policy","link":"/privacy-policy"}]}]},"social":{"youtube":"@paganidesign","instagram":"pagani_design_watch","facebook":"paganidesignwatch","pinterest":"","twitter":"","snapchat":""},"paymentMethods":["visa","mastercard","amex","diners","discover","jcb","paypal"],"shopifyOptions":{"password":"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","storeUrl":"onemorewatch.myshopify.com","shopifyConnections":["collections"],"salesChannel":"pagani","prioritize":false}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de","fr","es","pt","it","ar"],"defaultLanguage":"en","siteUrl":"https://www.paganidesignwatch.net/","verbose":false,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true,"excludeLanguages":["es"]},{"matchPath":"/show/:id/:product","languages":["en"]},{"matchPath":"/404","languages":["en"]},{"matchPath":"/dev-404-page","languages":["en"]},{"matchPath":"/cancle","languages":["en"]},{"matchPath":"/page-2","languages":["en"]},{"matchPath":"/successes","languages":["en"]},{"matchPath":"/successed","languages":["en"]}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.paganidesignwatch.net"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
